* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: #333;
}

h1 {
  font-weight: 800;
  line-height: 1;
}

h3 {
  line-height: 1;
}

textarea:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus, .uneditable-input:focus {
  border-color: rgb(254 18 81 / 14%);
  box-shadow: 0 1px 1px rgb(0 0 0 / 8%) inset, 0 0 8px rgb(254 18 81 / 22%);
  outline: 0 none;
}



button.card-button.read-more {
  background-color: #fe1251;
  border-color: #fe1251;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle, button.card-button.read-more:hover {
  background-color: #bb103e;
  border-color: #bb103e;
}

button.card-button.add-cart {
  background-color: #000;
  border-color: #000;
}

button.card-button.add-cart:hover {
  background-color: #128707;
      border-color: #128707;
}



.Content {
  font-family: sans-serif;
  width: 80vw;
  margin: 0 auto;
  padding: 2rem 0;
  list-style: none;
  /* display: flex;  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-row-gap: 1rem;
  grid-column-gap: 20px; */
  justify-items: center;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
}

.Searchbar {
  display: flex;
  background: #fe1251;
  height: 80px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Pagination {
  display: flex;
  margin-bottom: 50px;
  justify-content: space-evenly;
}

.Pagination button {
  border: 0;
  background: #fe1251;
  border-radius: 20px;
  padding: 5px 21px;
  box-shadow: 0 3px 12px 0 #00000029;
}


.card .card-header {
  display: flex;
  padding: 0;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: transparent;
  flex-direction: row-reverse;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: unset;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px, rgba(0, 0, 0, 0.13) 0px 0px 0px;
  padding: 16px;
  overflow: hidden;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(241, 241, 241);
  border-image: initial;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s, 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}


.card:hover {
  box-shadow: rgb(51 51 51 / 15%) 0px 7px 15px, rgb(0 0 0 / 5%) 0px 0px 3px;
  border-color: rgb(254 18 81 / 32%);
}

.rating.col {
display: flex!important;
align-items: center;
font-size: 14px;
color: #c5c5c5;
}

.rating.col div {
margin-right: 10px;
}

.product-pictures {
display: flex;
flex-direction: column;
align-items: center;
margin-bottom: 40px
}

.product-pictures div img {
margin: 10px;
}

/* .col {
  display: flex;
  justify-content: center;
} */

.card-body {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: end;
  justify-content: flex-end;
  margin-right: 0px;
}

span.badge.rounded-pill {
  font-size: 10px;
  font-weight: 100;
  text-transform: uppercase;
  padding: 10px 10px;
  line-height: 0;
  padding-top: 11px;
  margin-bottom: 20px;
}

.carousel-item {
transition: -webkit-transform 0.5s ease;
transition: transform 0.5s ease;
transition: transform 0.5s ease, -webkit-transform 0.5s ease;
-webkit-backface-visibility: visible;
        backface-visibility: visible;
}


.carousel-inner img {
object-fit: cover;
height: 170px;
}

.card-title {
  font-size: 16px;
  font-weight: 800;
  color: rgb(51, 51, 51);
  margin-top: 25px;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.product-info {
border: 1px solid #fe1251;
border-radius: 10px;
padding: 20px;
}

.ProductCards .row .col {
display: flex;
justify-content: center;
}

.description {
min-height: 21px;
margin-bottom: 30px;
font-size: 13px;
line-height: 20px;
color: rgba(51, 51, 51, 0.75);
}

.price {
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 15px;
  margin-bottom: 7px;
  letter-spacing: normal;
  color: #fe1251;
  white-space: nowrap;
}

.single {
  font-size: 20px;
}

.small-text {
  font-family: Inter, sans-serif;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: normal;
}

.form-searchbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 70px;
}

.input-searchbar {
  width: 100%;
  max-width: 500px;
  border: 0;
  padding: 10px 20px;
  border-radius: 100px;
}

.LoginForm, .RegisterForm, .MyAccount {
width: 285px;
margin: 0 auto;
text-align: center;
}

.LoginForm form, .RegisterForm form, .MyAccount Form {
  margin: 0 auto;
  max-width: 240px;
  border: 2px solid #fe1251;
  border-radius: 10px;
  padding: 20px 20px;
  background: #f1f1f1;
}

.LoginForm h2, .RegisterForm h2, .MyAccount h2 {
  color: #fff;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 21px;
}

.LoginForm .login-title, .RegisterForm .login-title, .MyAccount .login-title {
  width: 220px;
  height: 80px;
  background-color: #fe1251;
  border-radius: 100px;
  margin: 0 auto;
  margin-bottom: -50px;
  padding-top: 3px;
}

.LoginForm .login-image, .RegisterForm .login-image, .MyAccount .login-image {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  margin: 0 auto;
  margin-bottom: 20px;
  background: url(./profile.png);
  background-position: center;
  background-size: cover;
}

.LoginForm input, .RegisterForm input, .MyAccount input {
margin-bottom: 20px;
}

.LoginForm a, .RegisterForm a, .MyAccount a {
display: block;
}

.LoginForm button, .RegisterForm button, .MyAccount button {
width: 100%;
background-color: #fe1251;
border-color: #fe1251;
}

.MyAccount button.btn-link  {
width: 100%;
background-color: #fff;
border-color: #f8f9fa;
margin-bottom: 10px;
}

.LoginForm .btn-sign-up, .RegisterForm .btn-sign-up, .MyAccount .btn-sign-up {
width: 100%;
background-color: #8f8f8f;
border-color: #8f8f8f;
margin-bottom: 10px;
}

.LoginForm .btn-sign-up:hover, .RegisterForm .btn-sign-up:hover, .MyAccount .btn-sign-up:hover {
background-color: #000;
border-color: #000;
}

.navbar-light .navbar-fakeshop {
  color: rgba(0,0,0,.55);
  border-color: rgba(0,0,0,.1);
}

.btn-danger {
  color: #fff;
  background-color: #fe1251;
  border-color: #fe1251;
}

.btn-link {
  color: #fe1251;
}

.btn-link:hover {
    color: #912240;
}

.offcanvas-start {
  width: 250px;
}



.messages {
	width: 100%;
	/* height: calc(100vh - 260px); */
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	font: 16px/1.3 sans-serif;
	overflow: auto;
	padding: 0;
	background: linear-gradient(#fe1251 0%, rgb(167, 151, 255) 50%, rgb(0, 229, 255) 100%);
}

.messages:after {
	content: '';
	background: white;
	flex: 1;
	pointer-events: none;
}

.messages li {
	list-style-type: none;
	display: flex;
	flex-direction: row-reverse;
	overflow: hidden;
	border-style: solid;
	border-color: white;
	border-width: 8px 16px 0px 8px;
	flex: 0 0 auto;
}

.messages li.mine {
	flex-direction: row;
	border-right-width: 8px;
	border-left-width: 16px;
}

.messages .chat-icon {
	width: 22px;
	height: 22px;
	margin: 0px 9px 5px 0px;
}

/* .messages li span.author {
	display: flex;
	justify-content: flex-start;
	font-size: 10px;
} */

.messages li.mine .date, .messages li.mine .author, .messages li .author {
	font-size: 10px;
	text-align: right;
	justify-content: flex-end;
}

.messages li .text {
	overflow-wrap: break-word;
}

.messages li:first-child {
	border-top-width: 10px;
}

.messages li:last-child {
	border-bottom-width: 10px;
}

.messages li:before {
	content: '';
	flex: 1;
	background: white;
	pointer-events: none;
}

.messages li .message {
	background: #eee;
	padding: 14px;
	position: relative;
	margin-bottom: 0;
	min-width: 268px;
	animation-name: message-effect;
	animation-duration: .5s;
	animation-fill-mode: forwards;
	transform: translateY(5px);
}

.messages li .message:before {
	content: '';
	position: absolute;
	left: -8px;
	top: -8px;
	bottom: -8px;
	right: -8px;
	border: 8px solid white;
	border-radius: 25px;
	pointer-events: none;
}

.messages li.mine .message {
	background: none;
	color: white;
}



@keyframes message-effect {
	to {
		opacity: 1;
		transform: translateY(0);
	}
}



.offcanvas-body .nav-link:first-child {
	border-top: 1px dotted#fe1251;
}

.offcanvas-body .nav-link {
	color: #fe1251;
	border-bottom: 1px dotted#fe1251;
}

.offcanvas-body .nav-link:focus,
.offcanvas-body .nav-link:hover,
.offcanvas-body a.active.nav-link {
	color: #000000;
	background: #a5a5a51c;

}

.offcanvas-body .menu-icons {
	height: 16px;
	margin-top:-4px
}


.offcanvas-body a:hover .menu-icons {
	-webkit-animation: infinite-spinning 1s ease-out 0s infinite normal;
	animation: infinite-spinning 1s ease-out 0s infinite normal;
	animation-iteration-count: 1
}


.ViewMyAccount, .ViewChat, .ViewShop, .ViewRegister, .ViewLogin, .Contact, .NoMatch, .NoLogged, .ViewSingleProduct {
	min-height: calc(100vh - 125px);
}

.footer {
	font-size: 12px;
	background: #e5e5e5;
	display: flex;
	align-items: center;
	color: #2c2c2c;
	flex-direction: column;
	justify-content: center;
	padding: 14px 0px 7px 0px;
}

.footer p {
	margin-bottom: 4px;
}

.footer a {
	color: #8d8d8d;
	text-decoration: none;
}

@keyframes infinite-spinning {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.loader {
  margin: 100px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #fe1251, 1.8em -1.8em 0 0em rgba(254,18,81, 0.2), 2.5em 0em 0 0em rgba(254,18,81, 0.2), 1.75em 1.75em 0 0em rgba(254,18,81, 0.2), 0em 2.5em 0 0em rgba(254,18,81, 0.2), -1.8em 1.8em 0 0em rgba(254,18,81, 0.2), -2.6em 0em 0 0em rgba(254,18,81, 0.5), -1.8em -1.8em 0 0em rgba(254,18,81, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(254,18,81, 0.7), 1.8em -1.8em 0 0em #fe1251, 2.5em 0em 0 0em rgba(254,18,81, 0.2), 1.75em 1.75em 0 0em rgba(254,18,81, 0.2), 0em 2.5em 0 0em rgba(254,18,81, 0.2), -1.8em 1.8em 0 0em rgba(254,18,81, 0.2), -2.6em 0em 0 0em rgba(254,18,81, 0.2), -1.8em -1.8em 0 0em rgba(254,18,81, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(254,18,81, 0.5), 1.8em -1.8em 0 0em rgba(254,18,81, 0.7), 2.5em 0em 0 0em #fe1251, 1.75em 1.75em 0 0em rgba(254,18,81, 0.2), 0em 2.5em 0 0em rgba(254,18,81, 0.2), -1.8em 1.8em 0 0em rgba(254,18,81, 0.2), -2.6em 0em 0 0em rgba(254,18,81, 0.2), -1.8em -1.8em 0 0em rgba(254,18,81, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(254,18,81, 0.2), 1.8em -1.8em 0 0em rgba(254,18,81, 0.5), 2.5em 0em 0 0em rgba(254,18,81, 0.7), 1.75em 1.75em 0 0em #fe1251, 0em 2.5em 0 0em rgba(254,18,81, 0.2), -1.8em 1.8em 0 0em rgba(254,18,81, 0.2), -2.6em 0em 0 0em rgba(254,18,81, 0.2), -1.8em -1.8em 0 0em rgba(254,18,81, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(254,18,81, 0.2), 1.8em -1.8em 0 0em rgba(254,18,81, 0.2), 2.5em 0em 0 0em rgba(254,18,81, 0.5), 1.75em 1.75em 0 0em rgba(254,18,81, 0.7), 0em 2.5em 0 0em #fe1251, -1.8em 1.8em 0 0em rgba(254,18,81, 0.2), -2.6em 0em 0 0em rgba(254,18,81, 0.2), -1.8em -1.8em 0 0em rgba(254,18,81, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(254,18,81, 0.2), 1.8em -1.8em 0 0em rgba(254,18,81, 0.2), 2.5em 0em 0 0em rgba(254,18,81, 0.2), 1.75em 1.75em 0 0em rgba(254,18,81, 0.5), 0em 2.5em 0 0em rgba(254,18,81, 0.7), -1.8em 1.8em 0 0em #fe1251, -2.6em 0em 0 0em rgba(254,18,81, 0.2), -1.8em -1.8em 0 0em rgba(254,18,81, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(254,18,81, 0.2), 1.8em -1.8em 0 0em rgba(254,18,81, 0.2), 2.5em 0em 0 0em rgba(254,18,81, 0.2), 1.75em 1.75em 0 0em rgba(254,18,81, 0.2), 0em 2.5em 0 0em rgba(254,18,81, 0.5), -1.8em 1.8em 0 0em rgba(254,18,81, 0.7), -2.6em 0em 0 0em #fe1251, -1.8em -1.8em 0 0em rgba(254,18,81, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(254,18,81, 0.2), 1.8em -1.8em 0 0em rgba(254,18,81, 0.2), 2.5em 0em 0 0em rgba(254,18,81, 0.2), 1.75em 1.75em 0 0em rgba(254,18,81, 0.2), 0em 2.5em 0 0em rgba(254,18,81, 0.2), -1.8em 1.8em 0 0em rgba(254,18,81, 0.5), -2.6em 0em 0 0em rgba(254,18,81, 0.7), -1.8em -1.8em 0 0em #fe1251;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #fe1251, 1.8em -1.8em 0 0em rgba(254,18,81, 0.2), 2.5em 0em 0 0em rgba(254,18,81, 0.2), 1.75em 1.75em 0 0em rgba(254,18,81, 0.2), 0em 2.5em 0 0em rgba(254,18,81, 0.2), -1.8em 1.8em 0 0em rgba(254,18,81, 0.2), -2.6em 0em 0 0em rgba(254,18,81, 0.5), -1.8em -1.8em 0 0em rgba(254,18,81, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(254,18,81, 0.7), 1.8em -1.8em 0 0em #fe1251, 2.5em 0em 0 0em rgba(254,18,81, 0.2), 1.75em 1.75em 0 0em rgba(254,18,81, 0.2), 0em 2.5em 0 0em rgba(254,18,81, 0.2), -1.8em 1.8em 0 0em rgba(254,18,81, 0.2), -2.6em 0em 0 0em rgba(254,18,81, 0.2), -1.8em -1.8em 0 0em rgba(254,18,81, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(254,18,81, 0.5), 1.8em -1.8em 0 0em rgba(254,18,81, 0.7), 2.5em 0em 0 0em #fe1251, 1.75em 1.75em 0 0em rgba(254,18,81, 0.2), 0em 2.5em 0 0em rgba(254,18,81, 0.2), -1.8em 1.8em 0 0em rgba(254,18,81, 0.2), -2.6em 0em 0 0em rgba(254,18,81, 0.2), -1.8em -1.8em 0 0em rgba(254,18,81, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(254,18,81, 0.2), 1.8em -1.8em 0 0em rgba(254,18,81, 0.5), 2.5em 0em 0 0em rgba(254,18,81, 0.7), 1.75em 1.75em 0 0em #fe1251, 0em 2.5em 0 0em rgba(254,18,81, 0.2), -1.8em 1.8em 0 0em rgba(254,18,81, 0.2), -2.6em 0em 0 0em rgba(254,18,81, 0.2), -1.8em -1.8em 0 0em rgba(254,18,81, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(254,18,81, 0.2), 1.8em -1.8em 0 0em rgba(254,18,81, 0.2), 2.5em 0em 0 0em rgba(254,18,81, 0.5), 1.75em 1.75em 0 0em rgba(254,18,81, 0.7), 0em 2.5em 0 0em #fe1251, -1.8em 1.8em 0 0em rgba(254,18,81, 0.2), -2.6em 0em 0 0em rgba(254,18,81, 0.2), -1.8em -1.8em 0 0em rgba(254,18,81, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(254,18,81, 0.2), 1.8em -1.8em 0 0em rgba(254,18,81, 0.2), 2.5em 0em 0 0em rgba(254,18,81, 0.2), 1.75em 1.75em 0 0em rgba(254,18,81, 0.5), 0em 2.5em 0 0em rgba(254,18,81, 0.7), -1.8em 1.8em 0 0em #fe1251, -2.6em 0em 0 0em rgba(254,18,81, 0.2), -1.8em -1.8em 0 0em rgba(254,18,81, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(254,18,81, 0.2), 1.8em -1.8em 0 0em rgba(254,18,81, 0.2), 2.5em 0em 0 0em rgba(254,18,81, 0.2), 1.75em 1.75em 0 0em rgba(254,18,81, 0.2), 0em 2.5em 0 0em rgba(254,18,81, 0.5), -1.8em 1.8em 0 0em rgba(254,18,81, 0.7), -2.6em 0em 0 0em #fe1251, -1.8em -1.8em 0 0em rgba(254,18,81, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(254,18,81, 0.2), 1.8em -1.8em 0 0em rgba(254,18,81, 0.2), 2.5em 0em 0 0em rgba(254,18,81, 0.2), 1.75em 1.75em 0 0em rgba(254,18,81, 0.2), 0em 2.5em 0 0em rgba(254,18,81, 0.2), -1.8em 1.8em 0 0em rgba(254,18,81, 0.5), -2.6em 0em 0 0em rgba(254,18,81, 0.7), -1.8em -1.8em 0 0em #fe1251;
  }
}

